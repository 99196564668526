@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&family=Roboto:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&family=Roboto:wght@700&display=swap");
body {
  margin: 0;
  font-family: "Montserrat";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Montserrat";
  box-sizing: border-box;
}

code {
  font-family: "Montserrat";
  font-weight: 300;
}

button {
  /* box-shadow: -2px -2px 5px white, 3px 3px 5px rgb(0 0 0 / 20%) !important; */
  transition: box-shadow 0.2s ease-in-out;
}

button:hover {
  /* box-shadow: inset -2px -2px 5px white, inset 3px 3px 5px rgb(0 0 0 / 20%) !important; */
}

#react-select-3-placeholder {
  font-size: 13px;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-300%);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(300%);
  }
}

.privacyPolicy {
  padding: 2em;
  width: 75em;
  max-width: 100%;
  margin: auto;
}
